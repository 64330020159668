import styled, { css, keyframes } from 'styled-components';
// import { themeGet } from 'styled-system';

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const AnimSpinner = css`
	animation: ${spinner} 4s linear infinite;
`;

const DemoSwitcherWrapper = styled.div`
	width: 40px;
	position: fixed;
	z-index: 99991;
	right: 0;
	top: calc(50% - 110px / 2);

	@media (max-width: 767px) {
		width: 34px;
	}
	.reusecore__button {
		width: 100%;
		min-width: 100%;
		padding: 12px 0 10px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		font-weight: 400;
		font-size: 12px;
		letter-spacing: 1px;
		text-transform: uppercase;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
		background-color: rgb(47, 128, 237);
		@media (max-width: 767px) {
			padding: 7px 0 7px;
		}
		.btn-text {
			writing-mode: vertical-rl;
			text-orientation: upright;
		}
		.btn-icon {
			justify-content: center;
			margin: 0;
			svg {
				fill: '#ffffff';
				${AnimSpinner}
			}
		}
		transition: padding-right 0.3s ease;
		&:hover {
			@media (min-width: 768px) {
				padding-right: 12px;
			}
		}
	}
`;

const DemoListWrapper = styled.div`
	width: calc(80% - 20px) !important;
	margin: 71px auto;
	border-radius: 8px;
	padding: 48px 30px;
	background-color: #fff;
	box-shadow: 0px 15px 50px 0px rgba(23, 53, 102, 0.5);

	@media (max-width: 1300px) {
		max-width: 1170px;
		width: 90%;
	}

	> div {
		> div {
			&.coming_soon {
				position: relative;
				&::after {
					content: 'Coming Soon';
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					text-align: center;
					display: block;
					padding-top: 33%;
					font-size: 20px;
					color: #0f2137;
					font-weight: 500;
					letter-spacing: -0.025em;
					background-color: rgba(255, 255, 255, 0.75);
				}
			}
		}
	}
	h2 {
		font-size: 30px;
		font-weight: 400;
		color: #0f2137;
		letter-spacing: -0.025em;
		text-align: center;
		margin-bottom: 50px;
		line-height: 40px;
		@media (max-width: 600px) {
			font-size: 24px;
			margin-bottom: 25px;
		}
	}
	h3 {
		box-sizing: border-box;
		margin-bottom: 1rem;
		margin-top: 0px;
		font-size: 16px;
		color: rgb(15, 33, 55);
		font-weight: 400;
		text-align: center;
		letter-spacing: -0.025em;
	}
	.productWrap {
		display: flex;
		flex-wrap: wrap;
		margin-left: -15px;
		margin-right: -15px;
		.productCol {
			width: calc(100% / 3 - 30px);
			padding-left: 15px;
			padding-right: 15px;
			margin-bottom: 25px;
			cursor: pointer;
			@media (max-width: 768px) {
				width: calc(100% / 2 - 30px);
				margin-bottom: 0px;
			}
			@media (max-width: 600px) {
				width: 100%;
			}

			&:nth-child(17) {
				pointer-events: none;
				img {
					filter: blur(3px);
					pointer-events: none;
				}
			}
			img {
				margin-bottom: 0;
				outline: 0;
				border: 0;
			}
		}
	}
`;

const ProductCard = styled.div`
	display: block;
	color: #5268db;
	&:hover,
	&:focus {
		outline: none;
		text-decoration: none;
		img {
			transform: translateY(-15px);
			box-shadow: 0px 30px 28.2px 1.8px rgba(34, 48, 102, 0.06);
		}
		.badge {
			transform: translateY(-15px);
		}
		.btn_wrapper {
			transform: translateY(15px);
		}
	}
	> a {
		display: block;
		position: relative;
		&.current_demo {
			opacity: 0.6;
		}
	}
`;

const ImageWrapper = styled.div`
	position: relative;
	margin-bottom: 35px;
	@media (max-width: 768px) {
		margin-bottom: 0px;
		margin-top: 20px;
	}

	img {
		border-radius: 7px;
		background-color: rgb(208, 208, 208);
		box-shadow: 0px 6px 14.1px 0.9px rgba(34, 48, 102, 0.1);
		overflow: hidden;
		transform: translateY(0);
		transition: all 0.3s ease;
		@media (max-width: 990px) {
			transform: translateY(-15px);
		}
	}
`;

const Badge = styled.span`
	display: inline-block;
	padding: 8px 10px;
	border-radius: 4px;
	position: absolute;
	top: -8px;
	left: -8px;
	font-size: 13px;
	font-weight: 700;
	z-index: 1;
	color: #ffffff;
	background-color: #5268db;
	box-shadow: 0px 8px 19px -2px rgba(75, 109, 235, 0.57);
	transition: all 0.3s ease;
	@media (max-width: 990px) {
		transform: translateY(-35px);
	}
`;

export { DemoListWrapper, ProductCard, ImageWrapper, Badge };

export default DemoSwitcherWrapper;
